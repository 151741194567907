
.logo-container {
    padding: 20px;
    width: 100%;
    background-image: url(https://leadlensai.com/wp-content/uploads/2024/03/200x200-lead-lens-logo.png);
    background-repeat: no-repeat;
    height: 125px;
    margin-top:  20px;
    margin-bottom:  20px;
    background-position: center;
    background-size: contain;
}

.ant-menu-item {
    border-bottom:solid 1px #b4b7b9;
    Border-radius:0px;
    padding:0px;
    margin:0px;
}

.detail-view .ant-descriptions-item-label {
    color: black !important;
    width: 25%;
}

.ant-table-container {
    border-color: #dddddd;
    border-style: solid;
    border-width: 1px;
}

.detail-header {
    width: 100%;
    font-size: larger;
    background-color:#ffffff;
    padding-top: 8px;
    border-radius: 5px;
    border-style: solid;
    border-color: #dddddd;
    padding-bottom: 0px;
    margin-bottom: 16px;
}

.detail-header h1 {
    color: #207edc;
    font-size: 24px;
    font-weight: bold;
    padding: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.detail-header h2 {
    background-color:#207edc;
    color: white;
    font-weight: bold;
    font-size: larger;
    padding: 16px;
    margin-bottom: 0px;
}

.detail-view .ant-descriptions-item-content {
    width: 70%;
}
.detail-view .ant-descriptions-header {
    margin-bottom: 0px;
    padding-top:8px;
    padding-bottom:14px;
}

.detail-view .ant-descriptions-title, .rating-badge-title {
    font-size: 18px;
}

.rating-badge-title {
    color: #207edc;
    font-weight: bold;
}

.detail-view .ant-descriptions-view {
    margin-bottom: 40px;
}

.ant-carousel {
    padding: 12px;
    background-color: #eeeeee;
    color: black;
    font-weight: normal;
    font-size: 14px;
}

.slick-list {
    margin-top: 50px;
}

.ant-layout-sider-children {
    display: contents;
}

.ant-select-selection-overflow-item {
    width: 100%
}

.ant-select-selection-overflow-item > span {
    width: 100%
}

.ant-select-selection-overflow-item > span > div > span.close-x-button {
    position: absolute;
    right: 10px;
}

.ant-alert-description {
    display: block;
    color: #207edc !important;
    font-size: larger;
    font-weight: bold;
}

.ant-alert-info {
    background: white;
    border: 2px solid #207edc !important;
}

.ant-alert .anticon-info-circle {
    margin-right: 20px;
}

.company-selection-container {
    padding-top: 20px;
}

.ant-menu-item {
    border-radius: 0px !important;
}


@keyframes flashGreen {
    0% { background-color: rgba(0, 255, 0, 0.5); }
    100% { background-color: transparent; }
}

.flash-green {
    animation: flashGreen 1s;
}

.custom-list-item {
    list-style-type: none;
    padding-left: 0;
}

.ant-list-item.custom-list-item {
    padding-left: 0;
    border-bottom: none;
}

.ant-list-item.custom-list-item:last-child {
    border-bottom: none;
}

@keyframes fadeInBlue {
    from { color: #1890ff; font-weight: bold; }
    to { color: rgba(0, 0, 0, 0.85); font-weight: normal; }
}

@keyframes fadeInBlue {
    from { color: #1890ff; font-weight: bold; }
    to { color: #1890ff; font-weight: bold; }
}


@keyframes flashGreenInput {
    0% { background-color: rgba(0, 255, 0, 0.2); }
    100% { background-color: transparent; }
}

.flash-green-input .ant-input {
    animation: flashGreenInput 2s;
}


.item-enter {
    opacity: 0;
    transform: translateY(-20px);
}

.item-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1000ms, transform 1000ms;
}

.item-exit {
    opacity: 1;
}

.item-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 1000ms, transform 1000ms;
}

.notification-item {
    transition: color 0.5s, font-weight 0.5s;
}

.notification-new {
    color: #1890ff;
    font-weight: bold;
}

.notification-enter {
    opacity: 0;
    transform: translateY(-20px);
}

.notification-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1000ms, transform 1000ms;
}

.notification-exit {
    opacity: 1;
}

.notification-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 1000ms, transform 1000ms;
}

